html[data-bs-theme="dark"] {
  & .treeselect-input, & .treeselect-input__edit, & .treeselect-list {
    background-color: var(--bs-body-bg);
    color: var(--bs-body-color);
  }

  & .treeselect-input, & .treeselect-list {
    border: var(--bs-border-width) solid var(--bs-border-color);
  }

  & .treeselect-input {
    padding: 0.375rem 0.75rem;
  }

  & .treeselect-list__item--checked {
    background-color: var(--bs-gray-800);
  }

  & .treeselect-list__item--focused {
    background-color: var(--bs-green) !important;
  }
}
