@import "./treeselect.scss";

tr[data-document] {
  cursor: pointer;
  user-select: none;
}

table .snug {
  width: 1px;
  white-space: nowrap;
  text-align: center;
}

#search-results .snippet {
  display: none;
}

#search-results:has(input[name="snippet"]:checked) .snippet {
  display: block;
}

#mark-buttons {
  position: fixed;
  bottom: 1em;
  right: 1em;
  z-index: 9999;
}

mark.active {
  background-color: var(--bs-yellow);
}

.score-5 {
  background-color: var(--bs-green);
}

.score-4 {
  background-color: var(--bs-teal);
  color: #222222;
}

.score-3 {
  background-color: var(--bs-yellow);
  color: #222222;
}

.score-2 {
  background-color: var(--bs-orange);
  color: #222222;
}

.score-1 {
  background-color: var(--bs-pink);
}

.score-0 {
  background-color: var(--bs-red);
}
